import React, { useContext } from "react"
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  FormControl,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core"
import { Formik, Form, ErrorMessage } from "formik"
import { useTheme } from "@material-ui/styles"
import Button from "../../../Button"
import FormErrors from "../../../FormErrors"
import * as Yup from "yup"
import Paper from "../../../Paper"
import { useStyles } from "./style"

import LoanContext from "../../../../context/loan/loanContext"

const PartnerForm = () => {
  const classes = useStyles()
  const { isSubmitting, submitPartner } = useContext(LoanContext)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const initialValues = {
    salutation: "",
    name: "",
    phoneNumber: "",
    email: "",
    institution: "",
    website: "",
    message: "",
  }

  const validationSchema = Yup.object({
    salutation: Yup.string().required("Select a title"),
    name: Yup.string().required("Name is empty")
    .min(3, 'Not less than 3 characters')
    .test({
      name: 'validator-custom-name',
      //eslint-disable-next-line object-shorthand
      test: function(value) {
        return /\d/.test(value) ?
        this.createError({
          message: "Invalid name",
          path: 'name'
        }) : true
      }
    }),
    phoneNumber: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .min(9, "Phone Number must not be less than 9 characters")
      .required("Phone Number is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    institution: Yup.string().required("Institution is empty"),
    website: Yup.string().url("Invalid url format e.g (https://www.abc.com)"),
    message: Yup.string().required("Message is empty"),
  })

  return (
    <Box
      className={classes.root}
      paddingTop={isMobile ? "2rem" : "4rem"}
      paddingBottom={"3rem"}
    >
      <Container maxWidth={"md"}>
        <Box align="center">
          <Typography variant="h2" gutterBottom>
            Partner With Us Today
          </Typography>
          <Box className={classes.subTitle} marginBottom={3}>
            <Typography variant="body1">
             Join us to reduce financial barrier to education in Africa. Please fill out the interest form below and we will reach out to you.
            </Typography>
          </Box>
        </Box>

        <Paper padding={25} borderRadius={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (initialValues, { resetForm }) => {
              submitPartner(initialValues, resetForm)
            }}>
            {({ getFieldProps }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={isMobile ? 2 : 3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Title
                      </Typography>
                      <Select
                        id="salutation"
                        variant="outlined"
                        className={classes.select}
                        {...getFieldProps("salutation")}
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                      </Select>
                      <ErrorMessage name="salutation" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Full Name (Surname first)
                      </Typography>
                      <TextField
                        name="name"
                        id="name"
                        {...getFieldProps("name")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="name" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Phone Number
                      </Typography>
                      <TextField
                        name="phoneNumber"
                        id="phoneNumber"
                        {...getFieldProps("phoneNumber")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="phoneNumber" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Email Address
                      </Typography>
                      <TextField
                        name="email"
                        id="email"
                        {...getFieldProps("email")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="email" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Name Of Institution
                      </Typography>
                      <TextField
                        name="institution"
                        id="institution"
                        {...getFieldProps("institution")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="institution" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Website URL (Optional)
                      </Typography>
                      <TextField
                        name="website"
                        id="website"
                        placeholder="e.g https://www.abc.com"
                        {...getFieldProps("website")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="website" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>Message</Typography>
                      <TextField
                        name="message"
                        id="message"
                        fullWidth
                        multiline
                        rows={4}
                        {...getFieldProps("message")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="message" component={FormErrors} />
                    </FormControl>
                  </Grid>
                </Grid>

                <Box marginTop={7} align="right" paddingBottom={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </Box>
  )
}

export default PartnerForm
