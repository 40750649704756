
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F4F5F6",
  },
  subTitle: {
      width: "65%",
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      },
  },
  form: {
    width: "100%",
  },
  formControl: {
    minWidth: `100%`,
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    height: '40px'
},
}))

export { useStyles }
