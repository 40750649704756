import React from "react"
import {
  Container,
  Box,
  Typography,
  useMediaQuery,
  Grid,
} from "@material-ui/core"
import clsx from "clsx"
import { useStyles } from "./style"
import { useTheme } from "@material-ui/styles"
import RemoveIcon from "@material-ui/icons/Remove"
import Data from "../../Layout/Data"
import Button from "../../Button"
import PartnerForm from "./PartnerForm"

const PartnerWithUsLayout = props => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const {
    partner: { whyPartner, process },
  } = Data
  const { details } = whyPartner
  const classes = useStyles(whyPartner)
  return (
    <Box
      id="partner"
      position="relative"
      //   paddingTop={isMobile ? "8rem" : "9.5rem"}
      className={classes.root}
    >
      <Box
        className={classes.heroContainer}
        paddingTop={isMobile ? "8rem" : "9.5rem"}
      >
        {/* <Box className={classes.hero} paddingBottom="3rem">
          <Typography variant="h2" align="center" gutterBottom>
            Partner With Us
          </Typography>
          <Typography className={classes.subTitle} align="center" gutterBottom>
            As an Education Institution, Edutech platform or Portal provider,
            you can join us in reducing financial barriers to Education in
            Africa.
          </Typography>
        </Box> */}
        <Box className={classes.partnerImage} align="center">
          <Box className={classes.introContainer}>
            <Typography variant={isMobile ? "h3" : "h1"} color="inherit" gutterBottom>
              Partner With Us
            </Typography>
            <Typography variant={isMobile ? "caption" : "body1"}>
              As an education institution, Edtech platform or Portal provider,
              you can join us in reducing financial barriers to Education in
              Africa.{" "}
            </Typography>
            <Box marginTop={isMobile ? "1rem" : "3rem"}>
              <a href="#form" style={{textDecoration: "none", color: "#ffffff"}}>
              <Button variant="outlined">Get Started</Button>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.body}>
        <Container>
          {/* <Box className={classes.whatIs} align="center">
            <Typography gutterBottom variant="h4">
              What is Educollect?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Educollect is a platform that aims to solve the problem of access
              to finance, which is a major barrier to quality education in
              Africa today. Our goal is to ensure that education journeys don’t
              miss a beat, by providing seamless access to collaeral-free loans.
              We partner with multiple funders and Education Institutions to
              provide finance &amp; value services to Students, Parents and
              Education service providers across various academic institutions.
            </Typography>
            <Box marginTop={2}>
              <Link to="/about-us" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="primary">
                  Learn More
                </Typography>
              </Link>
            </Box>
          </Box> */}

          <Box marginTop={"1rem"} align="center">
          <Typography variant="h4" gutterBottom>Partnering with us offers you the opportunity to:</Typography>
            <Box className={classes.whyPartner} marginTop={5}>
              {details.map((detail, index) => {
                const { icon, title, text } = detail
                return (
                  <Box
                    className={
                      // index === 1
                      //   ? clsx(classes.why, classes.second)
                      //   : classes.why
                      classes.why
                    }
                    align="center"
                    key={index}
                  >
                    <div className={classes.iconContainer}>
                      {index < 2 ? <img src={icon} width="22rem" alt="" /> : icon}
                    </div>
                    <Box marginTop={"1rem"}>
                      <Typography color="primary" variant="h5" gutterBottom>
                        {title}
                      </Typography>
                      <Typography variant="body1">{text}</Typography>
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Container>
        <Box className={classes.processes} marginTop={"6rem"}>
          <Container maxWidth={"md"}>
            <Typography variant="h2" align="center">
              The Process
            </Typography>
            <Box align={isMobile ? "left" : "center"}>
              <div className={classes.decoration1}></div>
            </Box>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={false} sm={6}></Grid>
              <Grid item xs={12} sm={6} className={classes.rightGrid}>
                {process.map((p, index) => (
                  <Box
                    marginTop={index === 2 && isMobile ? "1.5rem" : ""}
                    className={
                      index === 1 || index === 3
                        ? clsx(classes.leftItem, classes.process)
                        : classes.process
                    }
                    key={index}
                  >
                    <div className={classes.pIconContainer}>
                      <div className={classes.pIconSubContainer}>
                        <RemoveIcon
                          style={{ color: "white", fontSize: "15px" }}
                        />
                      </div>
                    </div>
                    <Typography color="primary" variant="h4" gutterBottom>
                      {p.title}
                    </Typography>
                    <Typography variant="body1" style={{ color: "#18214D" }}>
                      {p.text}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
            <Box align={isMobile ? "left" : "center"}>
              <div className={classes.decoration2}></div>
            </Box>
          </Container>
        </Box>

        <Box className={classes.form} id="form">
          <PartnerForm />
        </Box>
      </Box>
    </Box>
  )
}

export default PartnerWithUsLayout
