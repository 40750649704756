import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#FFFFFF",
  },
  body: {
    padding: "2rem 0 5rem 0",
    "& p, li": {
      fontWeight: 400,
      fontSize: `1rem`, //16
    },
  },
  hero: {
    width: "40%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
     
  },
  whatIs: {
    width: "67%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
     
  },
  heroContainer: {
    backgroundColor: "rgb(242, 248, 255)",
  },
  subTitle: {
    color: "#24292E",
  },
  // whyPartner: {
  //   display:"grid",
  //   gridColumnGap:"10rem",
  //   gridRowGap: "1rem",
  //   gridTemplateColumns: "repeat(2, 1fr)",
  //   [theme.breakpoints.down("xs")]: {
  //       display: "block"
  //     },
  // },
  // why: {
  //   //   border: "2px solid green",
  //     height: "16rem",
  //     width: "100%",
  //     [theme.breakpoints.down("xs")]: {
  //       marginTop: "1rem",
  //       height: "18rem",
  //     },
  // },
  whyPartner: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "2rem",
    
  },
  why: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
  },
  second: {
    [theme.breakpoints.down("xs")]: {
        // marginTop: "3rem",
      },
  },
  iconContainer: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      backgroundColor: "#EFF2F6",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
  },
  processes: {
      backgroundColor: "#FAFBFD",
      padding: theme.spacing(5, 0),
  },
  process: {
      height: "12rem",
    //   border: "2px solid green",
      padding: "0 5rem 0 2rem",
      [theme.breakpoints.down("xs")]: {
        padding: "0"
      },
  },
  leftItem: {
      position: "relative",
      right: "100%",
      [theme.breakpoints.down("xs")]: {
        right: "0",
      },
  },
  rightGrid: {
      borderLeft: "2px solid #5B8EF0",
      [theme.breakpoints.down("xs")]: {
          paddingLeft: "1rem",
          marginLeft: "0.7rem"
      },
  },
  pIconContainer: {
      height: "40px",
      width: "40px",
      backgroundColor: "#EFF2F6",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "1rem"
  },
  pIconSubContainer: {
    height: "50%",
    width: "50%",
    backgroundColor: "#0F42A4",
    borderRadius: "50%",
    padding: "0 1px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}, 
decoration1: {
    height: "25px",
    width: "25px",
    backgroundColor: "#0F42A4",
    borderRadius: "50%",
    position: "relative",
    bottom: "-2rem"
},
decoration2: {
    height: "25px",
    width: "25px",
    backgroundColor: "#0F42A4",
    borderRadius: "50%",
    position: "relative",
},
partnerImage: {
  backgroundImage : props => `url(${props.image})`,
  height: "520px",
  backgroundSize: "cover",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  backgroundBlendMode: "multiply",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  
    backgroundPosition: "center center",
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down("sm")]: {
      height: "400px"
    },
    [theme.breakpoints.down("xs")]: {
      height: "200px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
  //     filter: "blur(1px)",
  // webkitFilter: "blur(1px)",
    },
},

introContainer: {
  // height: "13rem",
  width: "40%",
  // border: "2px solid green",
  color: "#ffffff",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "95%",
  },
}

}))

export { useStyles }
